import { type SpacingAlias } from "@yahoo/uds";
import dynamic from "next/dynamic";
import { type JSX } from "react";
import { type Site } from "@/types/Sites";

const logoMapping = {
  autos: dynamic(() => import("@/components/site/logos/AutosLogo")),
  health: dynamic(() => import("@/components/site/logos/HealthLogo")),
  local: dynamic(() => import("@/components/site/logos/LocalLogo")),
  shopping: dynamic(() => import("@/components/site/logos/ShoppingLogo")),
  tech: dynamic(() => import("@/components/site/logos/TechLogo")),
};

export const DynamicSiteLogo = ({
  site,
  spacingBottom,
}: {
  site: Site;
  spacingBottom?: SpacingAlias;
}): JSX.Element => {
  const SiteLogo = logoMapping[site];

  if (!SiteLogo) {
    return <div />;
  }

  return <SiteLogo spacingBottom={spacingBottom} />;
};
