"use client";

import { useEsiVars } from "@yahoo-commerce/edge";
import { UH3 } from "@yahoo-news/uh";
import { type Item } from "@yahoo-news/uh/lib/l2-navigation-data";
import { type EdgeSideIncludes } from "@/configs/esi";
import { useConfigContext } from "@/contexts/ConfigContext";
import { useNavigationDataContext } from "@/contexts/NavigationDataContext";
import { useRequestContext } from "@/lib/request/client";
import { type Site } from "@/types/Sites";

// TODO disable L2 once UH logo is updated as "yahoo!autos" version
export const SITES_DISABLE_L2: Array<Site> = ["autos"];

export const Header = ({
  l2NavData,
}: {
  l2NavData?: Array<Item>;
  site?: Site;
}) => {
  const { crumb } = useEsiVars<Pick<EdgeSideIncludes, "crumb">>({ crumb: "" });
  const { header } = useNavigationDataContext();
  const {
    header: { mailAppId, subnav },
  } = useConfigContext();
  const { features, partner, lang, region, site } = useRequestContext();

  let subnavData = l2NavData;
  if (!!features?.presetSubnav && subnav) {
    // If presetSubnav is enabled, use the subnav from config if exists
    subnavData = subnav;
  } else if (!subnavData && header?.length) {
    // If l2NavData is not provided, use the header data from the NavigationDataContext
    subnavData = header as Item[];
  } else if (!subnavData) {
    // If no header data is available, fallback to empty array
    subnavData = [];
  }

  return (
    <UH3
      mailAppId={mailAppId}
      site={site}
      lang={lang}
      region={region}
      crumb={crumb}
      partner={partner}
      showL2Navigation={subnavData.length > 0}
      subnavData={subnavData}
    />
  );
};
