"use client";

import { useEsiVars } from "@yahoo-commerce/edge";
import { Footer } from "@yahoo-commerce/footer";
import { type FC } from "react";
import { DynamicSiteLogo } from "@/components/site/Logo";
import { type EdgeSideIncludes } from "@/configs/esi";
import { useConfigContext } from "@/contexts/ConfigContext";
import { useNavigationDataContext } from "@/contexts/NavigationDataContext";
import { useRequestContext } from "@/lib/request/client";

export const SiteFooter: FC = () => {
  const { lang, site, partner } = useRequestContext();
  const { footer } = useConfigContext();
  const { footer: categoryLinks } = useNavigationDataContext();
  const footerLinks = {
    ...footer?.links,
    ...(categoryLinks?.length > 0 && {
      category: categoryLinks,
    }),
  };

  const { acookieFields } = useEsiVars<Pick<EdgeSideIncludes, "acookieFields">>(
    {
      acookieFields: "",
    },
  );

  return (
    <Footer
      acookieFields={acookieFields}
      lang={lang}
      logo={<DynamicSiteLogo site={site} />}
      partner={partner}
      links={footerLinks}
    />
  );
};
